header {
  @apply h-16 border-b;
}

.header-content {
  @apply container flex items-center justify-between h-full;
}

.naming {
  @apply font-medium uppercase text-xl;
}
