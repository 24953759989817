.scrolled-areas {
  @apply flex w-full h-[352px] max-w-[278px];
}

.scrolled-areas > div {
  @apply h-full w-full text-center px-[3px] rounded-md border;
}

.scrolled-areas :first-child {
  @apply rounded-r-none;
}

.scrolled-areas :last-child {
  @apply rounded-l-none border-l-0;
}

.scrolled-areas .item {
  @apply cursor-pointer h-9 w-full hover:bg-accent flex items-center justify-center p-1 rounded-md text-sm;
}
.scrolled-areas .item.active {
  @apply bg-primary text-white;
}
