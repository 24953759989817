.file-uploader {
  @apply flex items-center rounded-md justify-center  cursor-pointer;
  height: 198px;
  overflow: hidden;
  opacity: 0.5;
}

.file-uploader svg {
  stroke: #d0d0d0;
}
.progress {
  position: relative;
  appearance: none;
  overflow: hidden;
  height: 0.5rem /* 8px */;
  border-radius: var(--rounded-box, 1rem /* 16px */);
}
.progress::-moz-progress-bar {
  --tw-bg-opacity: 1;
  @apply bg-secondary;
}
.progress:indeterminate::after {
  --tw-bg-opacity: 1;
  @apply bg-primary;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -40%;
  width: 33.333333%;
  border-radius: var(--rounded-box, 1rem /* 16px */);
  animation: progress-loading 5s infinite ease-in-out;
}
.progress::-webkit-progress-bar {
  @apply bg-secondary;
  --tw-bg-opacity: 0.2;
  border-radius: var(--rounded-box, 1rem /* 16px */);
}
.progress::-webkit-progress-value {
  --tw-bg-opacity: 1;
  @apply bg-primary;
  border-radius: var(--rounded-box, 1rem /* 16px */);
}
